import { defineComponent } from '@vue/composition-api';
import type {} from '@vue/composition-api';

import Card from '@/components/Card';
import Activity from '@/components/Article/Activity';

export default defineComponent({
    components: { Card, Activity },
    setup() {
        return {};
    },
});
